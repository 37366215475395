import React, { useState } from 'react'
import axios from 'axios'
import { kellioApiUrl } from '../config/api'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import CircularProgress from '@material-ui/core/CircularProgress'
import { translateText } from '../config/translation'

const useStyles = makeStyles(() => ({
  formElement: {
    display: 'block',
    maxWidth: '340px',
    margin: '48px auto 0 auto',
    '& p': {
      margin: 0,
    }
  },
  notFoundText: {
    backgroundColor: '#fbd5c6',
  },
  button: {
    marginTop: 32,
    padding: '0 42px',
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 48,
  },
}))

const RSVPGetInvitationForm = ({ lang }) => {
  const classes = useStyles()
  
  const [loading, setLoading] = useState(false)
  const [submissionStatus, setSubmissionStatus] = useState('not_sent')
  const [emailInput, setEmailInput] = useState('')
  const [invalidEmailInput, setInvalidEmailInput] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const handleEmailChange = event => {
    setEmailInput(event.target.value);
  }

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      handleSubmit()
    }
    else if (event.keyCode === 27) {
      setEmailInput('')
    }
  }

  const handleSubmit = () => {
    setLoading(true)
    axios.post(`${ kellioApiUrl }/rsvp/send-invitation-code`, { email: emailInput })
    .then(resp => {
      if (resp.status === 200) {
        const status = resp.data.status
        switch (status) {
          case 'sent_by_drupal':
            setSubmissionStatus('sent')
            break;
          case 'not_sent_by_drupal':
            setSubmissionStatus('server_error')
            break;
          case 'email_not_found':
            setSubmissionStatus('email_not_found')
            setInvalidEmailInput(emailInput)
            setErrorMessage(`"${emailInput}" ${translateText("was not found. Please check if the email was typed in correctly and try again.", lang)}`)
            break;
          default:
            setSubmissionStatus('not_sent')
            setErrorMessage(translateText("Well, this is embarassing but the website failed to email the invitation because of an internal server error. Please try again.", lang))
            break;
        }
        setLoading(false)
      }
      else {
        // TODO: HANNDLE ERROR
        setSubmissionStatus('server_error')
        setErrorMessage(translateText("Well, this is embarassing but the website failed to email the invitation because of an internal server error. Please try again.", lang))
        setLoading(false)
      }
    })
    .catch(error => {
      // TODO: HANNDLE ERROR
      setSubmissionStatus('server_error')
      setErrorMessage(translateText("Well, this is embarassing but the website failed to email the invitation because of an internal server error. Please try again.", lang))
      setLoading(false)
    })
  }

  const instructions = translateText("Please enter your email.", lang)
  const helpText = translateText('The invitation will be emailed to you.', lang)

  let content = null
  if (loading) {
    content = <Box component='span' className={classes.spinner}><CircularProgress /></Box>
  }
  else {
    switch (submissionStatus) {
      case 'sent':
        content = <Box>
          <h3>The RSVP link was sent to your email.</h3>
        </Box>
        break
      case 'server_error':
      case 'email_not_found':
      default:
        content = <Box>
          <FormControl className={classes.formElement}>
            { errorMessage ? <p className={ classes.notFoundText }>{ errorMessage }</p> : '' }
            <p>{ instructions }</p>
            <TextField id="name" label="Email" value={ emailInput } onChange={ handleEmailChange } onKeyDown={handleKeyDown} required={ true } margin='normal' variant="outlined" fullWidth error={ invalidEmailInput.length !== 0 } />
            <Box m="8px" />
            <p>{ helpText }</p>
          </FormControl>
          <Button className={ classes.button } onClick={ handleSubmit }>
            { (lang === 'pt') ? 'ENVIAR' : 'SUBMIT' }
          </Button>
        </Box>
        break
    }
  }

  return content
}

export default RSVPGetInvitationForm